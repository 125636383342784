// 放置所有请求地址

const urlBase = 'https://api.1633short.com/manager'

const urls = {
    // 登录接口
    signin: `${urlBase}/account/login`,
    logout: `${urlBase}/account/logout`,
    // 用户菜单
    userMenus: `${urlBase}/admin/menus`,
    // 文件
    fileUpload: `${urlBase}/file/upload`,
    // 人员
    getAdminList: `${urlBase}/admin/page`,
    getAdminRoles: `${urlBase}/admin/roles`,
    adminDispatchRoles: `${urlBase}/admin/roles`, // 注意：与getAdminRoles重复，可能需要检查业务需求
    checkAccount: `${urlBase}/admin/account/check`,
    checkEmail: `${urlBase}/admin/email/check`,
    createAdmin: `${urlBase}/admin/create`,
    updateAdmin: `${urlBase}/admin/update`,
    deleteAdmin: `${urlBase}/admin/`,
    resetAdminPassword: `${urlBase}/admin/password/reset`,
    // 角色
    getRoleList: `${urlBase}/role/page`,
    getAllRoles: `${urlBase}/role/list`,
    createRole: `${urlBase}/role/create`,
    updateRole: `${urlBase}/role/update`,
    deleteRole: `${urlBase}/role/`,
    roleDispatchMenus: `${urlBase}/role/menus`,
    roleMenus: `${urlBase}/role/menu/list`,
    roleMenuIds: `${urlBase}/role/menu/id/list`,
    // 用户
    getCustomerList: `${urlBase}/customer/page`,
    // 语言
    getLanguageList: `${urlBase}/lang/list`,
    createLanguage: `${urlBase}/lang/create`,
    updateLanguage: `${urlBase}/lang/update`,
    deleteLanguage: `${urlBase}/lang/`,
    changeLanguageStatus: `${urlBase}/lang/enabled`,
    sortLanguage: `${urlBase}/lang/sort`,
    // 公司
    getCompanyList: `${urlBase}/company/page`,
    createCompany: `${urlBase}/company/create`,
    updateCompany: `${urlBase}/company/update`,
    deleteCompany: `${urlBase}/company/`,
    // 分类
    getCategoryList: `${urlBase}/category/page`,
    createCategory: `${urlBase}/category/create`,
    updateCategory: `${urlBase}/category/update`,
    deleteCategory: `${urlBase}/category/`,
    // 剧集
    getDramaList: `${urlBase}/drama/page`,
    createDrama: `${urlBase}/drama/create`,
    updateDrama: `${urlBase}/drama/update`,
    changeDramaStatus: `${urlBase}/drama/update/status`,
    deleteDrama: `${urlBase}/drama/`,
    getDramaEpisodes: `${urlBase}/drama/episode/page`,
    updateDramaPrice: `${urlBase}/drama/price`,
    updateDramaEpisodePrice: `${urlBase}/drama/all/price`,
    getDramaSelectList: `${urlBase}/select/drama/list`,
    getCategoriesByDrama: `${urlBase}/drama/tag/list/`,
    addTagToDrama: `${urlBase}/drama/tag/add`,
    removeTagFromDrama: `${urlBase}/drama/tag/del`,
    // 单集
    getEpisodeList: `${urlBase}/episode/page`,
    getEpisode: `${urlBase}/episode/`,
    createEpisode: `${urlBase}/episode/create`,
    updateEpisode: `${urlBase}/episode/update`,
    changeEpisodeStatus: `${urlBase}/episode/update/status`,
    deleteEpisode: `${urlBase}/episode/`,
    sortEpisode: `${urlBase}/drama/update/episode/sort`,
    addEpisode: `${urlBase}/drama/episode`,
    removeEpisode: `${urlBase}/drama/episode`,
    updateEpisodePrice: `${urlBase}/episode/price`,
    getEpisodeSelectList: `${urlBase}/select/episode/list`,
    // 布局
    getLayoutList: `${urlBase}/advert/layout/list`,
    updateLayoutList: `${urlBase}/advert/layout/update/`,
    // 轮播
    getBannerList: `${urlBase}/banner/page`,
    createBanner: `${urlBase}/banner/create`,
    updateBanner: `${urlBase}/banner/update`,
    changeBannerStatus: `${urlBase}/banner/update/status`,
    sortBanner: `${urlBase}/banner/update/sort`,
    deleteBanner: `${urlBase}/banner/`,
    // 每月推荐
    getRecomList: `${urlBase}/recommend/page`,
    createRecom: `${urlBase}/recommend/create`,
    updateRecom: `${urlBase}/recommend/update`,
    deleteRecom: `${urlBase}/recommend/`,
    getRecomDramaList: `${urlBase}/recommend/drama/page`,
    addRecomDrama: `${urlBase}/recommend/drama/add`,
    deleteRecomDrama: `${urlBase}/recommend/drama/delete`,
    sortRecomDrama: `${urlBase}/recommend/drama/sort`,
    // 轮播
    getMessageList: `${urlBase}/message/page`,
    createMessage: `${urlBase}/message/create`,
    updateMessage: `${urlBase}/message/update`,
    sendMessage: `${urlBase}/message/send/`,
    deleteMessage: `${urlBase}/message/`,
    // 充值记录
    getRechargeList: `${urlBase}/recharge/page`,
    exportTop100: `${urlBase}/recharge/top100/export`,
    exportRanged: `${urlBase}/recharge/list/export`,
    // 充值配置
    getRechargePlanList: `${urlBase}/recharge/config/page`,
    createRechargePlan: `${urlBase}/recharge/config/create`,
    updateRechargePlan: `${urlBase}/recharge/config/update`,
    deleteRechargePlan: `${urlBase}/recharge/config/`,
    sortRechargePlan: `${urlBase}/recharge/config/update/sort`,
    // 支付
    getPayChannelList: `${urlBase}/pay/channel/list`,
    getPayChannel: `${urlBase}/pay/channel`,
    updatePayChannel: `${urlBase}/pay/channel/update`,
    // 广告
    getAdvertList: `${urlBase}/advert/list`,
    createAdvert: `${urlBase}/advert/create`,
    updateAdvert: `${urlBase}/advert/update`,
    // 邀请码
    getInvitationList: `${urlBase}/invitation/code/page`,
    createInvitation: `${urlBase}/invitation/code/create`,
    updateInvitation: `${urlBase}/invitation/code/update`,
    deleteInvitation: `${urlBase}/invitation/code/`
}

export default urls
